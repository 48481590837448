/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Global Modal Styling */
.modal-content {
    background-color: #f8f9fa; // Light background for the modal
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    .modal-header {
        background-color: #eef0fa; // Dark background for the header
        color: #ffffff; // White text for the header
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }

    .modal-title {
        color: black;
    }

    .modal-footer {
        background-color: #eef0fa; // Light grey background for the footer
        border-top: 1px solid #dee2e6;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }
}

.btn-light {
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    color: #495057;
}

.btn-success {
    background-color: #28a745;
    color: #ffffff;
}

.btn-primary {
    background-color: #4a8cd1;
    border: 1px solid #3a7bbb;
    color: #ffffff;

    &:hover {
        background-color: #3a7bbb;
        border-color: #2a6aad;
    }
}

.btn.disabled, .btn:disabled {
    cursor: not-allowed;
    pointer-events: all;
}